function fetchOther311() {
  return [
  {
    "coordinates": [
      -73.9546996925444,
      40.77380758020999
    ],
    "category": "High",
    "Complaint_Type_collection": "Street Condition"
  },
  {
    "coordinates": [
      -74.01686172400147,
      40.70617085122013
    ],
    "category": "High",
    "Complaint_Type_collection": "Street Condition"
  },
  {
    "coordinates": [
      -73.98929730900083,
      40.75955431363341
    ],
    "category": "High",
    "Complaint_Type_collection": "Street Condition"
  },
  {
    "coordinates": [
      -73.95418696351773,
      40.77387325121576
    ],
    "category": "High",
    "Complaint_Type_collection": "Street Condition"
  }
];
}

export default fetchOther311;